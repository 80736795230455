import AOS from "aos";

$(document).ready(function () {

    const menuElement = document.getElementById('slide-menu');
    const allEvents = [
        'sm.back',
        'sm.open',
        'sm.close'
    ];

    allEvents.forEach(eventName => {
        menuElement.addEventListener(eventName, event);
    });


    const menu = new SlideMenu(menuElement, {
        position: 'left',
        submenuLinkAfter: ' <i class="fas fa-chevron-right float-right mt-1"></i>',
        backLinkBefore: '<i class="fas fa-chevron-left mr-2"></i> ',
    });

    menuElement.addEventListener('sm.open', function () {
        $('.menu_overlay').removeClass('d-none');
    })
    menuElement.addEventListener('sm.close', function () {
        $('.menu_overlay').addClass('d-none');
    })
    $('.menu_overlay').on('click', function () {
        menu.close();
    })

    AOS.init();
});
